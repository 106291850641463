const tabs = () => {
    let tabButtonWrapper = document.querySelector('.portfolio__tabs'),
          tabItems = document.querySelectorAll('.portfolio__tab-item');

    function tabInit (e) {
        tabButtonWrapper = document.querySelector('.portfolio__tabs'),
        tabItems = document.querySelectorAll('.portfolio__tab-item');

        if (e.target.classList.contains('portfolio__tab')) {
            [...this.children].forEach(elem => {
                elem.classList.remove('active');
            })
            e.target.classList.add('active');
            tabItems.forEach(tab => {
                tab.classList.add('fade-reverse');
                setTimeout(() => {
                    tab.classList.add('hide');
                    tab.classList.remove('fade-reverse');
                }, 200);   
            })
            setTimeout(() => {
                tabItems[e.target.getAttribute('data-tab')].classList.remove('hide');
            }, 200);
        };
    }

    function tabReInit () {
        tabButtonWrapper = document.querySelector('.portfolio__tabs');
        tabButtonWrapper.removeEventListener('click', tabInit);
        tabButtonWrapper.addEventListener('click', tabInit);
    }

    tabButtonWrapper.addEventListener('click', tabInit);
    window.addEventListener('resize', tabReInit);
}

export default tabs;