import shiftCorection from "./shiftCorection";

const modalOpen = (dat) => {
    const modal = document.querySelector('.modal'),
          header = document.querySelector('.header'),
          scroll = shiftCorection(),
          headerPadding = window.getComputedStyle(header).paddingRight,
          headerScroll = parseInt(headerPadding) + scroll,
          modalCloser = document.querySelector('.modal__close'),
          submitButton = document.querySelector('.modal input[type="submit"]');


    modal.style.display = 'flex';
    document.body.style.cssText  = `overflow: hidden;`;
    document.body.style.marginRight = `${scroll}px`;
    header.style.paddingRight = `${headerScroll}px`; 

    function modalInnerHtml (html, butonValue, value) {
        const div = document.createElement('div');
        div.innerHTML = html
        div.classList.add('deletable');
        modalCloser.insertAdjacentElement('afterend', div);  
        submitButton.setAttribute('value', butonValue);

        const input = document.querySelector('#modal [name="theme"]');
        input.setAttribute('value', value)
        
    }
    try {
        switch (dat.getAttribute('data-modal')) {
            case 'presentation': 
            modalInnerHtml (
                `<h2 class="modal__label">We will send a <span class='green'> company presentation</span> soon</h2>`
                , 'Get a presentation', 'Send a presentation');
                break;
            case 'cost': 
                modalInnerHtml (
                    `<h2 class="modal__label">Calculate the cost of a <span>modular house</span> with a specialist </h2>
                    <div class="modal__descr">We will call you back soon</div>
                    <div class="modal__divider"></div>`
                    , 'Calculate cost', 'Calculate cost');
                break;
            case 'guaranties': 
                modalInnerHtml (
                    `<h2 class="modal__label">Specialist consultation <span>regarding the contract
                    for the construction</span> of a house</h2>
                    <div class="modal__descr">We will consult in the near future</div>
                    <div class="modal__divider"></div>`
                    , 'Consultation on the contract', 'Consultation on the contract');
                break;
            case 'popular': 
                    const moduleName = dat.parentElement.parentElement.children[1].children[0].innerHTML;
                modalInnerHtml (
                    `<h2 class="modal__label">Get detailed information about the module<br>
                    "<span>${moduleName}</span>"</h2>
                    <div class="modal__divider"></div>
                    <div class="modal__descr">We will call you back and consult on this module</div>
                    `
                    , 'Get information', `Give information about module ${moduleName}`);
                break;
            case 'consulting': 
            modalInnerHtml (
                `<h2 class="modal__label">Get advice on <span>modular houses</span> from a specialist </h2>
                <div class="modal__descr">We will consult in the near future</div>
                <div class="modal__divider"></div>`
                , 'Get a consultation', 'Consultation about houses from a specialist');
                break;
            case 'ready': 
                modalInnerHtml (
                    `<h2 class="modal__label">Get advice on <span>ready-made modules</span> from a specialist </h2>
                    <div class="modal__descr">We will consult in the near future</div>
                    <div class="modal__divider"></div>`
                    , 'Get consultation', 'Consultation regarding ready-made modules');
                    break;
            case 'custom': 
                modalInnerHtml (
                    `<h2 class="modal__label">Get advice on <span>modules to order</span> from a specialist </h2>
                    <div class="modal__descr">We will consult in the near future</div>
                    <div class="modal__divider"></div>`
                    , 'Get consultation', 'Consultation on custom modules');
                    break;
            default: console.log('Thank you');            
        }
    } catch (e) {};
    
    
}

export default modalOpen;