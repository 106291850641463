import { tns } from "tiny-slider";





const sliders = () => {
	function individual () {
		let a = tns({
		container: '.individual__slider',
		controls: true,
		nav: false,
		autoplay: true,
		autoplayButtonOutput: false,
		speed: 300,
		loop: false,
		// mouseDrag: true,
		items: 3,
		slideBy: 3,
		rewind: true,
		responsive: {
			320: {
				disable: true
			},
			991: {
				disable:false,
				items: 3
			}
		}
		});

		window.addEventListener('resize', () => {
			if (window.innerWidth <= 991 ) {
				if (a.isOn) {
					a.destroy();
				}  
			} else {
				if (a.isOn) {
					a.destroy();
				}            
				a  = tns({
					container: '.individual__slider',
					controls: true,
					nav: false,
					autoplay: true,
					autoplayButtonOutput: false,
					speed: 300,
					loop: false,
					// mouseDrag: true,
					items: 3,
					slideBy: 3,
					rewind: true,
					responsive: {
					320: {
						disable: true
					},
					991: {
						disable:false,
						items: 3
					}}
				});
				nextPrev ();
			}
		})
	}
	individual ();

	function tabSlider () {
		const tabHolder = document.querySelector('.portfolio__tabs'),
			tabs = document.querySelectorAll('.portfolio__tab');
			 
		let a = ['', '', ''];

		if (window.innerWidth < 992) { 
			a[0] = tns({
				container: `.portfolio__tab-item1`,
				controls: false,
				nav: true,
				navPosition: 'bottom',
				autoplay: true,
				autoplayButtonOutput: false,
				speed: 300,
				loop: false,
				mouseDrag: true,
				items: 1,
				slideBy: 1,
				rewind: true,
			});
			a[1] = tns({
				container: `.portfolio__tab-item2`,
				controls: false,
				nav: true,
				navPosition: 'bottom',
				autoplay: true,
				autoplayButtonOutput: false,
				speed: 300,
				loop: false,
				mouseDrag: true,
				items: 1,
				slideBy: 1,
				rewind: true,
			});
			a[2] = tns({
				container: `.portfolio__tab-item3`,
				controls: false,
				nav: true,
				navPosition: 'bottom',
				autoplay: true,
				autoplayButtonOutput: false,
				speed: 300,
				loop: false,
				mouseDrag: true,
				items: 1,
				slideBy: 1,
				rewind: true,
			});
			dotsControl ();
			tabHolder.addEventListener('click', tabHolderFunc);
		}
		
		function tabSliderRebuild () {
			if (window.innerWidth > 991 ) {
				console.log('isOn?..');
				if (a[0].isOn && a[1].isOn && a[2].isOn) {
						a[0].destroy();
						a[1].destroy();
						a[2].destroy();
				} 
				tabHolder.removeEventListener('click', tabHolderFunc);
			} else {
				try {
					if (a[0].isOn && a[1].isOn && a[2].isOn) {
						a[0].destroy();
						a[1].destroy();
						a[2].destroy();
				} 
				} catch (e) {};
				a[0] = tns({
					container: `.portfolio__tab-item1`,
					controls: false,
					nav: true,
					navPosition: 'bottom',
					autoplay: true,
					autoplayButtonOutput: false,
					speed: 300,
					loop: false,
					mouseDrag: true,
					items: 1,
					slideBy: 1,
					rewind: true,
				});
				a[1] = tns({
					container: `.portfolio__tab-item2`,
					controls: false,
					nav: true,
					navPosition: 'bottom',
					autoplay: true,
					autoplayButtonOutput: false,
					speed: 300,
					loop: false,
					mouseDrag: true,
					items: 1,
					slideBy: 1,
					rewind: true,
				});
				a[2] = tns({
					container: `.portfolio__tab-item3`,
					controls: false,
					nav: true,
					navPosition: 'bottom',
					autoplay: true,
					autoplayButtonOutput: false,
					speed: 300,
					loop: false,
					mouseDrag: true,
					items: 1,
					slideBy: 1,
					rewind: true,
				});
				dotsControl ();
				tabHolder.removeEventListener('click', tabHolderFunc);
				tabHolder.addEventListener('click', tabHolderFunc);
			}

		}
		window.addEventListener('resize', tabSliderRebuild);

		function dotsControl () {
			const tnsNav = document.querySelectorAll('.tns-nav');
			tabs.forEach((tab, i) => {
				if (tab.classList.contains('active')) {
					tnsNav[i].style.display = 'flex';
				} else {
					tnsNav[i].style.display = 'none';
				}
			})
		}
		
		function tabHolderFunc (e) {
			const tnsNav = document.querySelectorAll('.tns-nav');
			if (e.target.classList.contains('active')) {
				tabs.forEach((tab, i) => {
					if (tab.classList.contains('active')) {
						tnsNav[i].style.display = 'flex';
					} else {
						tnsNav[i].style.display = 'none';
					}	
				})
			}
		}
		
	}
	tabSlider ();

	function productionSlider () {
		let a;
		if (window.innerWidth < 992) {
			a = tns({
				container: `.production__galery`,
				controls: false,
				nav: true,
				navPosition: 'bottom',
				autoplay: true,
				autoplayButtonOutput: false,
				speed: 300,
				loop: false,
				mouseDrag: true,
				items: 1,
				slideBy: 1,
				rewind: true,
			});
		}

		window.addEventListener('resize', () => {
			if (window.innerWidth > 991 ) {
				try {
					if (a.isOn) {
						a.destroy();
					}  
				} catch (e) {};  
			} else {
				try {
					if (a.isOn) {
						a.destroy();
					}  
				} catch (e) {};   
				a  = tns({
					container: `.production__galery`,
					controls: false,
					nav: true,
					navPosition: 'bottom',
					autoplay: true,
					autoplayButtonOutput: false,
					speed: 300,
					loop: false,
					mouseDrag: true,
					items: 1,
					slideBy: 1,
					rewind: true,
				});
			}
		})
		
	}
	productionSlider ();

	function popularSlider () {
		const popularItems = document.querySelectorAll('.popular__slider');

		popularItems.forEach((item, i)=> {
			tns({
				container: `.popular__slider${i+1}`,
				controls: true,
				nav: false,
				autoplay: true,
				autoplayButtonOutput: false,
				speed: 300,
				loop: false,
				mouseDrag: true,
				items: 1,
				slideBy: 1,
				rewind: true,
			});
		})
	}
	popularSlider ();


	function nextPrev () {
		document.querySelectorAll('[data-controls]').forEach(control => {
			control.innerHTML = '';
		})
	}
	nextPrev ();
	
}

export default sliders;